import { useTranslation } from 'react-i18next';
import React from 'react';
import { Box, ListItem, ListItemText, Typography } from '@material-ui/core';

export const BoxCompare = ({ pros, cons }) => {
  const { t } = useTranslation('common');

  return (
    <Box display="flex" flexWrap="wrap" mt={4}>
      <Box mr={2} flex="1 1 220px">
        <Typography variant="h4">{t('vorteile')}</Typography>
        <ul>
          {pros.map((pro) => {
            return (
              <ListItem dense disableGutters style={{ display: 'list-item', marginBottom: '0' }} key={pro}>
                <ListItemText>{pro}</ListItemText>
              </ListItem>
            );
          })}
        </ul>
      </Box>
      <Box flex="1 1 220px">
        <Typography variant="h4">{t('nachteile')}</Typography>
        <ul>
          {cons.map((con) => {
            return (
              <ListItem dense disableGutters style={{ display: 'list-item', marginBottom: '0' }} key={con}>
                <ListItemText>{con}</ListItemText>
              </ListItem>
            );
          })}
        </ul>
      </Box>
    </Box>
  );
};
