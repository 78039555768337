import { Trans, Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { useUmoPromoteStyles } from './styles';

const CARDS = [
  {
    heading: 'heading.umoVorteil',
    content: 'content.umoVorteil',
  },
  {
    heading: 'heading.umoVorteil2',
    content: 'content.umoVorteil2',
  },
  {
    heading: 'heading.umoVorteil3',
    content: 'content.umoVorteil3',
  },
  {
    heading: 'heading.umoVorteil4',
    content: 'content.umoVorteil4',
  },
  {
    heading: 'heading.umoVorteil5',
    content: 'content.umoVorteil5',
  },
  {
    heading: 'heading.umoVorteil6',
    content: 'content.umoVorteil6',
  },
];

export const UmoPromote = () => {
  const { t } = useTranslation('common');

  const classes = useUmoPromoteStyles();

  return (
    <Box>
      <Box mb={1} mt={4}>
        <Box mb={1}>
          <Typography variant="h4">{t('heading.umo')}</Typography>
        </Box>
        <Typography>{t('content.wasIstUmo')}</Typography>
      </Box>
      <Box display="flex" mb={1} flexWrap="wrap">
        {CARDS.map((card) => {
          return (
            <Box className={classes.root} key={card.heading}>
              <Paper className={classes.paperRoot}>
                <Check fontSize="large" color="secondary" />
                <Typography className={classes.headingRoot} variant="h4">
                  {t(card.heading)}
                </Typography>
                <Typography className={classes.contentRoot} variant="body2">
                  {t(card.content)}
                </Typography>
              </Paper>
            </Box>
          );
        })}
      </Box>
      <Box mt={1}>
        <Typography>
          <Trans t={t} i18nKey="content.umoCheckProduct" components={[<Link key="0" to="/product" />]} />
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography>
          <Trans
            t={t}
            i18nKey="content.instructionGuideline"
            components={[<Link key="0" to="/how-to-create-an-instruction" />]}
          />
        </Typography>
      </Box>
    </Box>
  );
};
